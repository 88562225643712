import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Importing AOS CSS

const Hero = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true }); // Initialize AOS with desired settings
  }, []);

  return (
    <section className="relative h-screen flex items-center justify-center text-white">
      {/* Video Background */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
        src="https://ik.imagekit.io/devhm/6804109-uhd_4096_2160_25fps.mp4?updatedAt=1727724304381"
        type="video/mp4"
        autoPlay
        loop
        muted
        playsInline
      />

      {/* Dark Overlay for better readability */}
      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 z-10"></div>

      {/* Content */}
      <div className="relative z-20 text-center px-6">
        <h1 
          className="text-5xl md:text-7xl font-bold mb-6" 
          data-aos="fade-up"
        >
          Welcome to WebIQ
        </h1>
        <p 
          className="text-lg md:text-2xl mb-8 max-w-2xl mx-auto leading-relaxed" 
          data-aos="fade-up" 
          data-aos-delay="200"
        >
          Elevate your business with our cutting-edge digital solutions, designed to take you to the next level.
        </p>

        <a 
          href="tel:+919812887097" 
          className="inline-block px-8 py-3 bg-teal-500 hover:bg-teal-600 rounded-full text-lg font-medium transition duration-300 ease-in-out" 
          data-aos="fade-up" 
          data-aos-delay="400"
        >
          Call Us Now
        </a>
      </div>
    </section>
  );
};

export default Hero;
